import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/swipe/style";
import _Swipe from "vant/es/swipe";
import "vant/es/swipe-item/style";
import _SwipeItem from "vant/es/swipe-item";
import "vant/es/lazyload/style";
import _Lazyload from "vant/es/lazyload";
import "vant/es/icon/style";
import _Icon from "vant/es/icon";
import "vant/es/image/style";
import _Image from "vant/es/image";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.includes.js";
import { isIOS } from "@/utils/index";
import ZchIcon from "@/components/ZchIcon";
import { isPC } from "../../utils";
Vue.use(_Swipe).use(_SwipeItem).use(_Lazyload).use(_Icon).use(_Image);
export default {
  data: function data() {
    return {
      activeIndex: 0,
      images: [],
      exchangeProductList: null,
      isios: isIOS,
      text: "",
      //正文
      title: "",
      //标题
      showHeight: 0
    };
  },
  components: {
    ZchIcon: ZchIcon
  },
  props: {
    llStudyNewsId: String,
    llStudyNewsList: Object,
    name: String
  },
  created: function created() {
    var _this$llStudyNewsList, _this$llStudyNewsList2, _this$llStudyNewsList3;
    console.log("图文 this.name ==   1 ", this.name);
    console.log("图文 this.llStudyNewsList ==   1 ", this.llStudyNewsList);
    this.text = (_this$llStudyNewsList = this.llStudyNewsList) === null || _this$llStudyNewsList === void 0 || (_this$llStudyNewsList = _this$llStudyNewsList.imageTextNews) === null || _this$llStudyNewsList === void 0 ? void 0 : _this$llStudyNewsList.content;
    this.title = (_this$llStudyNewsList2 = this.llStudyNewsList) === null || _this$llStudyNewsList2 === void 0 || (_this$llStudyNewsList2 = _this$llStudyNewsList2.news) === null || _this$llStudyNewsList2 === void 0 ? void 0 : _this$llStudyNewsList2.title;
    this.images = ((_this$llStudyNewsList3 = this.llStudyNewsList) === null || _this$llStudyNewsList3 === void 0 || (_this$llStudyNewsList3 = _this$llStudyNewsList3.imageTextNews) === null || _this$llStudyNewsList3 === void 0 ? void 0 : _this$llStudyNewsList3.newsImageSet) || [];
    this.images.join({
      url: "/src/assets/companyWx.png",
      isLast: true
    });

    // 根据屏幕宽度和比例3:4计算显示高度

    console.log("图文 this.window.innerWidth; ==   4 ", window.innerWidth);
    var screenWidth = window.innerWidth;
    // this.showHeight = screenWidth * 4 / 3;
    console.log("图文 this.screenWidth; ==   5 ", screenWidth);
    var ispc = isPC();
    if (ispc) {
      this.showHeight = screenWidth * 2 / 5;
    } else {
      this.showHeight = screenWidth * 4 / 3;
    }
    var img = new Image();
    img.src = this.images[0] && this.images[0].url;
    img.onload = function () {
      // 关闭加载提示（如果已经超过 1 秒且加载已完成）
      setTimeout(function () {
        _Toast.clear();
      }, 500);
    };
    this.onChange(0);
  },
  watch: {
    llStudyNewsList: {
      handler: function handler(newVal, oldVal) {
        if (newVal) {
          this.text = this.llStudyNewsList.imageTextNews.content;
          this.title = this.llStudyNewsList.news.title;
          this.images = this.llStudyNewsList.imageTextNews.newsImageSet || [];
          this.images.join({
            url: "/src/assets/companyWx.png",
            isLast: true
          });
        }
      },
      deep: true
    }
  },
  methods: {
    onChange: function onChange(index) {
      console.log("图文 onchange index", index);
      this.activeIndex = index;
      if (index == this.images.length) {
        return;
      }
    },
    toImageAndTextList: function toImageAndTextList() {
      console.log("this.$router ====>?", this.$route.path);
      console.log("this.$nowSelectCountryCode ====>?", this.nowSelectCountryCode);
      if (this.nowSelectCountryCode) {
        this.$router.push({
          path: "/home/imageAndTextList?countryCode=".concat(this.nowSelectCountryCode)
        });
      }
    }
  },
  computed: {
    nowSelectCountryCode: function nowSelectCountryCode() {
      return this.$store.state.nowSelectCountryCode;
    },
    isHasSkip: function isHasSkip() {
      // 在详情页面则不展示跳转详情
      return !this.$route.path.includes("imageTextAndShowPage");
    }
  }
};