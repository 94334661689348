import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
export default {
  name: "ProductItemPOST",
  props: {
    type: String,
    productInfo: Object,
    disabled: Boolean,
    active: Boolean,
    displayCarrier: [Number, String],
    checkedPoint: Boolean,
    checkedRedPacket: Boolean,
    usableCouponList: Array,
    nowCouponList: [Array, Object]
  },
  data: function data() {
    return {
      isFirst: "首次优惠",
      isRechargeCard: "充值卡",
      isBatch: false
    };
  },
  created: function created() {
    /**
     * 如果为true的话就是批充
     * 则不展示卡密
     * */
    var reg = RegExp(/batch\/recharge/);
    this.isBatch = reg.test(this.$route.path);
    console.log("this.productInfo   =====?", this.productInfo);
    // console.log(this.isBatch,"正则判断true为批充，false为单充")
    // console.log(this.isBatch,"当前是否是批充。。")
    // console.log(this.productInfo.pin);
    // console.log("当前产品信息====>",this.productInfo)
  },
  computed: {
    currentPoint: function currentPoint() {
      return this.$store.state.currentPoint;
    },
    isFirstRechargeUser: function isFirstRechargeUser() {
      return this.$store.state.isFirstRechargeUser;
    }
  },
  methods: {
    updatecouponText: function updatecouponText(isOne, argNewText) {
      this.$emit("updateCouponText", "POSTPAID", isOne, argNewText); // 通过事件将修改后的值发送给父组件
    },
    ReferenceCoupon: function ReferenceCoupon() {
      var _this = this;
      // 初始化变量
      var usableCouponPriceList = [];
      var tempcouponList = [];
      var couponName = "";

      // 遍历优惠券，筛选符合条件的优惠券
      this.nowCouponList.forEach(function (coupon) {
        var couponPrice = (coupon.ilimits / 100).toFixed(2); // 满减金额

        // 判断是否符合价格区间和产品
        if (_this.productInfo.price > couponPrice && (coupon.products == null || coupon.products.includes(_this.productInfo.code))) {
          usableCouponPriceList.push(coupon.ivalue);
          tempcouponList.push(coupon);
          couponName = coupon.name; // 取第一个匹配的优惠券名字
        }
      });

      // 将筛选后的优惠券列表添加到可用优惠券列表中
      if (tempcouponList.length > 0) {
        this.usableCouponList.push({
          name: this.productInfo.name,
          coupon: tempcouponList
        });
      }

      // 如果有符合条件的优惠券，更新优惠券显示
      if (usableCouponPriceList.length > 0) {
        // 根据优惠券数量更新显示文字
        this.updatecouponText(usableCouponPriceList.length === 1, usableCouponPriceList.length === 1 ? couponName : usableCouponPriceList.length);
        console.log("usableCouponPriceList 0-----> ", usableCouponPriceList);
        this.isUseCoupon = true;

        // 计算最大优惠金额
        var maxFavorable = Math.max.apply(Math, usableCouponPriceList) / 100;
        this.maxFavorablePrice = (this.productInfo.price - maxFavorable).toFixed(2);
      }
      console.log("XXX 当前  -  ----》code = ", this.productInfo.code, "usableCouponPriceList = ", usableCouponPriceList);
    },
    // 展示什么提示页
    showTips: function showTips() {
      // 1 是否属于卡密 (充值卡)
      if (this.productInfo.pin && !this.isBatch) {
        console.log("111", this.isRechargeCard);
        console.log("111this.productInfo.pin && !isBatch", this.productInfo.pin && !this.isBatch);
        return this.isRechargeCard;
      } else if (this.productInfo.firstDiscount && this.isFirstRechargeUser && !this.productInfo.pin && !this.isBatch) {
        // 2 是否首次优惠
        console.log("2", this.isFirst);
        return this.isFirst;
      } else if (this.productInfo.hot) {
        // 3 是否热销
        console.log("333", this.productInfo.hotTips);
        return this.productInfo.hotTips;
      }
    },
    //是否热销
    isHot: function isHot() {
      // 批冲
      if (this.productInfo.pin && !this.isBatch) {
        return false;
      }

      // 首充
      if (this.productInfo.firstDiscount && this.isFirstRechargeUser && !this.productInfo.pin && !this.isBatch) {
        return false;
      }
      if (this.productInfo.hot) {
        return true;
      }
    }
  },
  watch: {
    displayCarrier: {
      handler: function handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.ReferenceCoupon();
        }
      }
    },
    nowCouponList: {
      handler: function handler(newVal, oldVal) {
        console.log("isFirstRechargeUser ---? ", this.isFirstRechargeUser);
        if (newVal && newVal.length > 0) {
          console.log("XXXnowCouponList newVal=====>?", newVal, this.type);
          console.log("XXXproductInfo productInfo=====>?", this.productInfo);
          this.ReferenceCoupon();
        }
      },
      immediate: true
    },
    currentPoint: {
      handler: function handler(newVal, oldVal) {
        console.log("抵扣 newVal", newVal);
        if (newVal && newVal > 0) {
          // 计算可以抵扣的金额，保留两位小数
          var discountAmount = (newVal / 100).toFixed(2);
          console.log("抵扣 discountAmount", discountAmount);
          // 计算优惠后的价格，并确保不低于 0.01 元
          this.afterTheDiscountPrice = Math.max((this.productInfo.price - discountAmount).toFixed(2), 0.01);
        }
      },
      immediate: true
    }
  }
};