var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "all"
  }, [_c("div", {
    staticClass: "savebutton",
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("button", {
    staticClass: "save",
    on: {
      click: _vm.TODO
    }
  }, [_vm._v("长按下方图片保存到手机相册")])]), _c("div", {
    ref: "saveIngImage",
    staticClass: "saveIngImage"
  }), _c("div", {
    ref: "imageToSave",
    staticClass: "ImageAll"
  }, [_vm._m(0), _c("div", {
    staticClass: "title"
  }, [_vm._v("Proforma Invoice")]), _c("div", {
    staticClass: "table"
  }, [_vm._m(1), _c("div", {
    staticClass: "right imageBottom"
  }, [_c("div", {
    staticClass: "one first chnelBorder taller"
  }, [_vm._v(_vm._s(_vm.orderInfo.outTradeNo))]), _c("div", {
    staticClass: "one chnelBorder taller"
  }, [_vm._v(_vm._s(_vm.orderInfo.created))]), _c("div", {
    staticClass: "one chnelBorder"
  }, [_vm._v(_vm._s(_vm.ename))]), _c("div", {
    staticClass: "one chnelBorder"
  }, [_vm._v(_vm._s(_vm.orderInfo.productName))]), _c("div", {
    staticClass: "one chnelBorder"
  }, [_vm._v(_vm._s(_vm.orderInfo.account))]), _c("div", {
    staticClass: "one chnelBorder"
  }, [_vm._v(_vm._s(_vm.orderInfo.payPrice))]), _c("div", {
    staticClass: "one chnelBorder"
  }, [_vm._v(_vm._s(_vm.orderInfo.status))])])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "pageHeader"
  }, [_c("img", {
    attrs: {
      src: "https://oss.globalcharge.cn/prod/wechat/src/assets/logo_unbg.png?x-oss-process=image/resize,p_10",
      alt: "游全球话费流量充值",
      width: "18px"
    }
  }), _c("span", {
    staticStyle: {
      "font-size": "8px",
      "margin-right": "18px"
    }
  }, [_vm._v("Global Recharge")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "left imageBottom"
  }, [_c("div", {
    staticClass: "one leftpadding first taller"
  }, [_c("span", [_vm._v("Transaction ID:")])]), _c("div", {
    staticClass: "one leftpadding taller"
  }, [_vm._v("Date(UTC+8)"), _c("br"), _vm._v("TimeZone:")]), _c("div", {
    staticClass: "one leftpadding"
  }, [_vm._v("Country:")]), _c("div", {
    staticClass: "one leftpadding"
  }, [_vm._v("Product/Service:")]), _c("div", {
    staticClass: "one leftpadding"
  }, [_vm._v("Phone number:")]), _c("div", {
    staticClass: "one leftpadding"
  }, [_vm._v("Amount (CNY):")]), _c("div", {
    staticClass: "one leftpadding"
  }, [_vm._v("Order Status:")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };