import _defineProperty from "/Users/jackh/Desktop/2024-web/20240305/global-charge-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/image/style";
import _Image from "vant/es/image";
import "vant/es/field/style";
import _Field from "vant/es/field";
import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import ZchIcon from "@/components/ZchIcon";
import ZchCountryEmpty from "@/components/ZchCountryEmpty";
export default {
  name: "ZchCountryList",
  components: _defineProperty(_defineProperty(_defineProperty(_defineProperty({
    ZchIcon: ZchIcon,
    ZchCountryEmpty: ZchCountryEmpty
  }, _Popup.name, _Popup), _Field.name, _Field), _Image.name, _Image), _Dialog.Component.name, _Dialog.Component),
  props: {
    countrySelectorVisible: Boolean,
    countryList: Array,
    selectedCountry: Object
  },
  computed: {
    _countrySelectorVisible: {
      set: function set(value) {
        this.$emit("close", value);
      },
      get: function get() {
        return this.countrySelectorVisible;
      }
    },
    filterCountryList: function filterCountryList() {
      var list = this.countryList;

      // 处理区号字段
      list.forEach(function (item) {
        item.anList = item.an.split(",");
      });

      // 1. 判断输入值是否非空
      if (!this.searchCountryKeyword) {
        return list; // 如果没有输入关键字，则返回完整列表
      } else {
        // 2. 将输入的字符串拆解为字符数组并且去重，加速后续处理
        var searchChars = new Set(this.searchCountryKeyword.split(""));
        var isNumeric = /^\d+$/.test(this.searchCountryKeyword); // 是否全为数字
        var isEnglish = /^[a-zA-Z]+$/.test(this.searchCountryKeyword); // 是否全为英文字母
        var hasChinese = /[\u4e00-\u9fa5]/.test(this.searchCountryKeyword); // 是否包含中文

        // 开始处理，从原始列表开始
        var result = list.map(function (item) {
          var score = 0; //得分 初始值 0
          searchChars.forEach(function (char) {
            if (isNumeric) {
              //如果是纯数字
              score += item.an.indexOf(char) > -1 ? 2 : 0; // 区号匹配加2分
              score += item.cc.indexOf(char) > -1 ? 1 : 0; // 国家代码匹配加1分
              score += item.cn.indexOf(char) > -1 ? 1 : 0; // 国家名称匹配加1分
              score += item.cc.toLowerCase().indexOf(char) > -1 ? 1 : 0; // 小写国家代码匹配加1分
            } else if (isEnglish && !hasChinese) {
              // 如果是纯英文 并且不含中文
              score += item.cc.indexOf(char) > -1 ? 2 : 0; // 国家代码匹配加2分
              score += item.cc.toLowerCase().indexOf(char) > -1 ? 2 : 0; // 小写国家代码匹配加2分
              score += item.an.indexOf(char) > -1 ? 1 : 0; // 区号匹配
              score += item.en.indexOf(char) > -1 ? 1 : 0; // 国家名称匹配加1分
            } else {
              // 包含中文或者混合输入
              score += item.cn.indexOf(char) > -1 ? 2 : 0;
              score += item.cc.indexOf(char) > -1 ? 1 : 0; // 国家代码匹配加2分
              score += item.cc.toLowerCase().indexOf(char) > -1 ? 2 : 0; // 小写国家代码匹配加2分
              score += item.an.indexOf(char) > -1 ? 1 : 0; // 区号匹配
            }
          });

          // 计算优先字段长度
          var length = 0;
          if (isNumeric) {
            //如果是纯数字
            length = item.an.length; // 使用区号的长度
          } else if (isEnglish && !hasChinese) {
            // 如果是纯英文
            length = item.cc.length;
          } else {
            length = item.cn.length;
          }
          return {
            item: item,
            score: score,
            length: length
          };
        }).filter(function (result) {
          return result.score > 0;
        }) // 过滤得分 0 的项
        .sort(function (a, b) {
          // 排序
          if (a.score !== b.score) {
            return b.score - a.score;
          }
          // 得分相同时，按长度从小到大排序
          return a.length - b.length;
        }).map(function (result) {
          return result.item;
        }); // 获取排序后的原始项

        return result; // 返回处理后的列表
      }
    }
  },
  data: function data() {
    return {
      searchCountryKeyword: "",
      countryInvalidVisible: false
    };
  },
  methods: {
    selected: function selected(item) {
      console.log("zchCountryList 中的 country", item);
      this.searchCountryKeyword = "";
      this.$emit("selected", item);
    },
    getRealImg: function getRealImg(item) {
      return "".concat(process.env.VUE_APP_IMG_URL, "countryFlags/").concat(item.ename, ".svg");
    }
  }
};