import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.llStudyNewsId ? _c("div", {
    staticClass: "imageAndText"
  }, [_vm.name == "LL" ? _c("div", {
    staticClass: "point"
  }, [_c("zch-icon", {
    attrs: {
      icon: "emptyTip"
    }
  }), _c("span", {
    staticStyle: {
      "font-size": "13px"
    }
  }, [_vm._v("提醒：暂无流量产品，如需充值请参考以下教程")])], 1) : _vm._e(), _c("div", [_c("van-swipe", {
    ref: "swipe",
    staticClass: "carousel-images",
    style: {
      width: "100%",
      height: "".concat(_vm.showHeight, "px")
    },
    attrs: {
      "indicator-color": "black"
    },
    on: {
      change: _vm.onChange
    }
  }, [_vm._l(_vm.images.concat({
    isLast: true
  }), function (image, index) {
    return _c("van-swipe-item", {
      key: index
    }, [image.isLast ? [_c("div", {
      staticClass: "keFuInfo",
      staticStyle: {
        "margin-top": "50px",
        height: "300px"
      },
      style: {
        height: "".concat(_vm.showHeight, "px")
      }
    }, [_c("div", {
      staticClass: "keFuImage",
      staticStyle: {
        display: "flex",
        "justify-content": "center"
      }
    }, [_c("img", {
      staticStyle: {
        width: "60%",
        height: "auto"
      },
      attrs: {
        src: "/src/assets/companyWx.png"
      },
      on: {
        dragstart: function dragstart($event) {
          $event.preventDefault();
        }
      }
    })]), _vm.images.length == _vm.activeIndex ? _c("div", {
      staticClass: "more",
      staticStyle: {
        "text-align": "center"
      }
    }, [_c("div", {
      staticClass: "more-button",
      staticStyle: {
        "margin-top": "30px"
      }
    }, [_c("img", {
      staticClass: "activeImage",
      staticStyle: {
        "margin-right": "10px"
      },
      attrs: {
        width: "35px",
        src: require("@/assets/home/kefu.png")
      }
    }), _c("span", [_vm._v("扫码寻求客服帮助")])]), _c("br"), _vm.isHasSkip ? _c("div", {
      staticClass: "more-button",
      on: {
        click: _vm.toImageAndTextList
      }
    }, [_c("img", {
      staticClass: "activeImage",
      staticStyle: {
        "margin-right": "10px"
      },
      attrs: {
        width: "35px",
        src: require("@/assets/home/gonglue.png")
      }
    }), _c("span", [_vm._v("查看更多相关教程")])]) : _vm._e()]) : _vm._e()])] : _vm._e(), [_c("van-image", {
      ref: "image",
      refInFor: true,
      staticClass: "images",
      attrs: {
        fit: "contain",
        src: image.url,
        "lazy-load": ""
      },
      scopedSlots: _vm._u([{
        key: "loading",
        fn: function fn() {
          return [_c("div", {
            staticClass: "loading-spinner"
          }, [_vm._v("图片加载中...")])];
        },
        proxy: true
      }], null, true)
    })]], 2);
  }), _c("div", {
    staticClass: "custom-indicator",
    attrs: {
      slot: "indicator"
    },
    slot: "indicator"
  }, [_vm._v(" " + _vm._s(_vm.activeIndex + 1) + " / " + _vm._s(_vm.images.length + 1) + " ")])], 2)], 1), _c("div", {
    staticClass: "text-all",
    staticStyle: {
      margin: "5px 5px"
    }
  }, [_c("div", {
    staticClass: "title",
    staticStyle: {
      margin: "3%",
      "margin-bottom": "30px",
      "font-size": "20px",
      "font-weight": "bold",
      color: "#333333"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c("div", {
    staticClass: "text-main",
    domProps: {
      innerHTML: _vm._s(_vm.text)
    }
  })])]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };