import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm$countryAlertInfo;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "rechargeCenter",
    staticStyle: {
      position: "relative"
    }
  }, [_c("div"), !_vm.aliLife && !_vm.ispc ? _c("div", {
    staticClass: "kefu-btn weizhi"
  }, [_c("div", {
    on: {
      click: _vm.toKeFu
    }
  }, [_c("i", {
    staticClass: "iconfont icon-kefu2 xiaoxi-btn"
  })])]) : _vm._e(), !_vm.aliLife && _vm.wxBrowser && _vm.ispc ? _c("div", {
    staticClass: "kefu-btn weizhi"
  }, [_c("div", {
    on: {
      click: _vm.toKeFu
    }
  }, [_c("i", {
    staticClass: "iconfont icon-kefu2 xiaoxi-btn"
  })])]) : _vm._e(), !_vm.aliLife && !_vm.wxBrowser && _vm.ispc ? _c("div", {
    staticClass: "kefu-btn2"
  }, [_c("div", {
    on: {
      click: _vm.toKeFu
    }
  }, [_c("i", {
    staticClass: "iconfont icon-kefu2 xiaoxi-btn"
  })])]) : _vm._e(), _c("header", [_c("img", {
    attrs: {
      width: "100%",
      src: _vm.HEADER_BG,
      alt: "充值就用游全球"
    }
  }), _c("p", [_vm._v("守护每一个身处海外中国人的手机通讯")])]), _c("main", [_c("div", {
    staticClass: "countryInput"
  }, [_c("div", {
    staticClass: "countryInput-top"
  }, [_c("div", {
    staticClass: "countryInput-top-img",
    on: {
      click: _vm.selectCountry
    }
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: _vm.displayIcon,
      alt: ""
    }
  })]), _c("div", {
    staticClass: "countryInput-top-wrapper"
  }, [_c("p", [_vm._v("+" + _vm._s(_vm.displayAreaNumber))]), _c("div", {
    staticClass: "countryInput-top-input"
  }, [_c("van-field", {
    ref: "phoneInput",
    attrs: {
      placeholder: "请输入" + _vm.displayCname + "手机号",
      minlength: "6",
      maxlength: "20",
      type: "tel",
      clearable: "",
      clickable: ""
    },
    on: {
      focus: _vm.getFocus,
      input: _vm.inputMethod,
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && $event.keyCode !== 13) return null;
        return _vm.inputEnter.apply(null, arguments);
      },
      blur: _vm.inputBlur
    },
    model: {
      value: _vm.displayPhoneNumber,
      callback: function callback($$v) {
        _vm.displayPhoneNumber = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "displayPhoneNumber"
    }
  })], 1)]), _c("ul", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.countryInputHighLight,
      expression: "countryInputHighLight"
    }],
    staticClass: "countryInput-top-phoneList"
  }, [_c("li", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.deepCopyUsedPhoneList.length == 0 && _vm.displayPhoneNumber.length > 0,
      expression: "deepCopyUsedPhoneList.length == 0 && displayPhoneNumber.length > 0"
    }],
    staticClass: "first-charge-tips"
  }, [_vm._v(" 该号码 "), _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v('"可能首次充值"，')]), _vm._v("请留意是否正确，避免充错 ")]), _vm._l(_vm.deepCopyUsedPhoneList, function (item, index) {
    return _c("li", {
      key: index,
      staticClass: "countryInput-top-phoneList-item",
      on: {
        mousedown: function mousedown($event) {
          $event.preventDefault();
        },
        click: function click($event) {
          $event.stopPropagation();
          return _vm.chooseUsedPhoneNumber(item);
        }
      }
    }, [_c("div", {
      staticClass: "countryInput-top-phoneList-item-top"
    }, [_c("p", [_vm._v(_vm._s(item.areaNumber))]), _c("em", [_vm._v(_vm._s(item.msisdn))])]), _c("div", {
      staticClass: "countryInput-top-phoneList-item-bottom"
    }, [_c("span", [_vm._v(_vm._s(item.cname) + "（" + _vm._s(item.carrierName) + "）")]), _c("div", {
      staticClass: "button-deletePhoneOne",
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.deletePhoneOne(item);
        }
      }
    })]), index === 0 ? _c("div", {
      staticClass: "rechargeDateTips",
      staticStyle: {
        color: "red"
      }
    }, [_vm._v(" 上次充值 ")]) : _vm._e(), index === 1 ? _c("div", {
      staticClass: "rechargeDateTips"
    }, [_vm._v(" " + _vm._s(_vm.formatTime(item.updated)) + " ")]) : _vm._e(), index === 2 ? _c("div", {
      staticClass: "rechargeDateTips"
    }, [_vm._v(" " + _vm._s(_vm.formatTime(item.updated)) + " ")]) : _vm._e(), _c("button", {
      staticClass: "phonedeleteButton",
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.delelteUserMsisdnRecord(item.id, item.msisdn);
        }
      }
    })]);
  }), _vm.deepCopyUsedPhoneList.length > 0 ? _c("div", {
    staticClass: "delete-all-phoneList"
  }, [_c("span", {
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.delelteUserMsisdnRecordAll();
      }
    }
  }, [_vm._v("全部删除")])]) : _vm._e()], 2)]), _c("div", {
    staticClass: "countryInput-bottom"
  }, [_vm.displayPhoneNumber.length > 0 ? _c("p", {
    class: {
      short: _vm.displayCname.length <= 3
    },
    staticStyle: {
      "margin-top": "7px",
      "font-weight": "600"
    },
    on: {
      click: _vm.selectCountry
    }
  }, [_vm._v(" " + _vm._s(_vm.displayCname) + " "), _c("van-icon", {
    staticStyle: {
      "font-weight": "600"
    },
    attrs: {
      name: "arrow-down"
    }
  })], 1) : _c("p", {
    staticStyle: {
      "margin-top": "7px",
      "font-weight": "600"
    },
    on: {
      click: _vm.selectCountry
    }
  }, [_vm._v(" 切换国家/地区"), _c("van-icon", {
    staticStyle: {
      "font-weight": "600"
    },
    attrs: {
      name: "arrow-down"
    }
  })], 1), _c("em", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.emptyTips === "请选择运营商",
      expression: "emptyTips === '请选择运营商'"
    }],
    on: {
      click: _vm.showCarrierPopup
    }
  }, [_vm._v(_vm._s(_vm.displayCarrier) + " "), _c("van-icon", {
    staticStyle: {
      "font-weight": "600"
    },
    attrs: {
      name: "arrow-down"
    }
  })], 1)]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.countryInputHighLight,
      expression: "countryInputHighLight"
    }],
    staticClass: "countryInput-top bg",
    on: {
      click: function click($event) {
        _vm.countryInputHighLight = false;
      }
    }
  })]), _c("section", {
    staticClass: "rechargeBar"
  }, [_c("van-tabs", {
    attrs: {
      swipeable: "",
      animated: "",
      sticky: "",
      color: "#0C7AD6",
      "line-height": "3.5px",
      background: "#F9F9F9",
      "title-active-color": "#222222",
      "title-inactive-color": "#222222",
      border: false
    },
    on: {
      click: _vm.clickTab,
      change: _vm.changeTab
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, _vm._l(_vm.myTabs, function (item, index) {
    return _c("van-tab", {
      key: item.name,
      attrs: {
        name: item.name
      }
    }, [_c("div", {
      attrs: {
        slot: "title"
      },
      slot: "title"
    }, [_c("b", {
      staticClass: "rechargeBar-title"
    }, [_vm._v(_vm._s(item.value))])]), item.name == "HF" || item.name == "LL" || item.name == "TC" || item.name == "POSTPAID" ? _c("div", [_c("product-list", {
      ref: item.name,
      refInFor: true,
      attrs: {
        active: index,
        name: item.name,
        displayBalanceQueryUrl: _vm.displayBalanceQueryUrl,
        emptyTips: _vm.emptyTips,
        displayCarrier: _vm.displayCarrierId,
        displayCarrierName: _vm.displayCarrier,
        displayPhoneNumber: _vm.displayPhoneNumber,
        displayAreaNumber: _vm.displayAreaNumber,
        displayCname: _vm.displayCname,
        displayCountryCode: _vm.displayCountryCode,
        disabledProduct: _vm.disabledProduct,
        countryAlertInfo: _vm.countryAlertInfo,
        carrierImgUrl: _vm.carrierImgUrl,
        isChangeCountry: _vm.isChangeCountry,
        proxyUser: _vm.proxyUser,
        toKeFu: _vm.toKeFu,
        bannersList: _vm.bannersList,
        deepCopyUsedPhoneList: _vm.deepCopyUsedPhoneList,
        llStudyNewsId: _vm.llStudyNewsId,
        llStudyNewsList: _vm.llStudyNewsList,
        pinStudyNewsId: _vm.pinStudyNewsId
      },
      on: {
        changeDisabledProduct: function changeDisabledProduct($event) {
          _vm.disabledProduct = $event;
        },
        ChangeisChangeCounty: _vm.ChangeisChangeCounty,
        showDiaLog: _vm.showDialog,
        showLLTCDetail: _vm.showLLTCDetail
      }
    })], 1) : item.name === "EXCHANGE" ? _c("div", [_c("Exchange", {
      ref: item.name,
      refInFor: true,
      attrs: {
        name: item.name,
        carrierId: _vm.displayCarrierId,
        countryCode: _vm.displayCountryCode,
        llStudyNewsId: _vm.llStudyNewsId,
        llStudyNewsList: _vm.llStudyNewsList
      },
      on: {
        "show-exchangeDialog": _vm.showExchangeDialog
      }
    })], 1) : _c("div", [_c("Search", {
      ref: item.name,
      refInFor: true,
      attrs: {
        carrierId: _vm.displayCarrierId,
        pinStudyNewsId: _vm.pinStudyNewsId
      },
      on: {
        showQRCode: _vm.showQRCode
      }
    })], 1)]);
  }), 1)], 1), _c("zch-country-list", {
    attrs: {
      "country-selector-visible": _vm.countrySelectorVisible,
      "selected-country": _vm.selectedCountry,
      "country-list": _vm.countryList
    },
    on: {
      close: function close($event) {
        _vm.countrySelectorVisible = $event;
      },
      selected: _vm.onConfirm
    }
  }), _c("zch-carrier-list", {
    attrs: {
      "carrier-visible": _vm.carrierVisible,
      "carrier-list": _vm.carrierList,
      "selected-carrier-name": _vm.selectedCarrierName,
      "active-tab": _vm.activeTab,
      "get-product-list": _vm.getProductList,
      "remove-product-list-active-index": _vm.removeProductListActiveIndex
    },
    on: {
      close: function close($event) {
        _vm.carrierVisible = $event;
      },
      selectedCarrier: function selectedCarrier($event) {
        return _vm.selectedCarrier($event);
      }
    }
  })], 1), _c("zch-country-empty", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isValid,
      expression: "!isValid"
    }]
  }), _c("van-dialog", {
    staticClass: "active",
    attrs: {
      width: "300px",
      title: "活动来袭",
      confirmButtonText: "点击进入",
      "show-cancel-button": ""
    },
    on: {
      confirm: _vm.activeEntrance
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c("img", {
    staticClass: "activeImage",
    attrs: {
      src: _vm.activelist.imageUrl
    }
  })]), _c("van-dialog", {
    attrs: {
      className: "vanDiaLog",
      title: "请扫描二维码进入微信公众号购买"
    },
    model: {
      value: _vm.showQrcode,
      callback: function callback($$v) {
        _vm.showQrcode = $$v;
      },
      expression: "showQrcode"
    }
  }, [_c("div", {
    staticStyle: {
      height: "40%",
      width: "40%",
      "text-align": "center",
      "margin-left": "30%"
    }
  }, [_c("img", {
    staticStyle: {
      height: "100%",
      width: "100%"
    },
    attrs: {
      src: _vm.WX_QR_CODE,
      alt: "微信公众号:游全球"
    }
  })])]), _c("van-dialog", {
    attrs: {
      className: "areaNumberSelect",
      title: "请选择区号",
      showConfirmButton: false
    },
    model: {
      value: _vm.checkedAreaNumberVisible,
      callback: function callback($$v) {
        _vm.checkedAreaNumberVisible = $$v;
      },
      expression: "checkedAreaNumberVisible"
    }
  }, _vm._l(_vm.AreaNumberList, function (item) {
    return _c("div", {
      staticClass: "my-btn",
      staticStyle: {
        "margin-bottom": "10px",
        "margin-top": "10px"
      }
    }, [_c("van-button", {
      attrs: {
        type: "primary",
        size: "small",
        color: "#00BFFF"
      },
      on: {
        click: function click($event) {
          return _vm.selectAreaNumber(item);
        }
      }
    }, [_vm._v("+" + _vm._s(item.areaNumber) + " " + _vm._s(item.countryName))])], 1);
  }), 0), _c("van-dialog", {
    attrs: {
      title: "",
      confirmButtonText: "购买",
      "show-cancel-button": ""
    },
    on: {
      confirm: _vm.showOrderDetail,
      close: _vm.closeOrderDetail
    },
    model: {
      value: _vm.LLTCDetailShow,
      callback: function callback($$v) {
        _vm.LLTCDetailShow = $$v;
      },
      expression: "LLTCDetailShow"
    }
  }, [_c("div", {
    staticClass: "orderDetail-box"
  }, [_c("div", {
    staticClass: "productDetail"
  }, [_vm._v(" " + _vm._s(_vm.orderDetail.name) + " ")]), _c("div", {
    staticClass: "deadline"
  }, [_vm.orderDetail.tips > 0 && _vm.orderDetail.tips < 99998 && _vm.orderDetail.unitValidity == "HOUR" ? _c("em", [_vm._v(_vm._s(_vm.orderDetail.tips) + "小时有效期")]) : _vm.orderDetail.tips > 0 && _vm.orderDetail.tips < 99998 && _vm.orderDetail.unitValidity == "DAY" ? _c("em", [_vm._v(_vm._s(_vm.orderDetail.tips) + "天有效期")]) : _vm.orderDetail.tips > 0 && _vm.orderDetail.tips < 99998 && _vm.orderDetail.unitValidity == "WEEK" ? _c("em", [_vm._v(_vm._s(_vm.orderDetail.tips) + "周有效期")]) : _vm.orderDetail.tips > 0 && _vm.orderDetail.tips < 99998 && _vm.orderDetail.unitValidity == "MONTH" ? _c("em", [_vm._v(_vm._s(_vm.orderDetail.tips) + "月有效期")]) : _vm.orderDetail.tips > 0 && _vm.orderDetail.tips < 99998 && _vm.orderDetail.unitValidity == "YEAR" ? _c("em", [_vm._v(_vm._s(_vm.orderDetail.tips) + "年有效期")]) : _vm.orderDetail.tips && _vm.orderDetail.tips == -1 ? _c("em", [_vm._v("用完即止")]) : _vm.orderDetail.tips && _vm.orderDetail.tips == 99999 ? _c("em", [_vm._v("套餐有效期")]) : _vm.orderDetail.tips && _vm.orderDetail.tips == 0 ? _c("em", [_vm._v("Sim有效期")]) : _vm._e(), _c("em", [_vm._v("￥" + _vm._s(_vm._f("toFixed2")(_vm.orderDetail.price)))])]), _c("div", {
    staticClass: "orderDetail-info"
  }, [_vm._v(" " + _vm._s(_vm.orderDetail.info) + " ")]), _vm.myPin ? _c("div", [_c("p", {
    staticClass: "card-text mb-2 text-muted my-pin"
  }, [_c("small", [_vm._v("1.该商品为卡密充值卡，购买后系统自动发放卡密，需手动兑换后到账，卡密发放成功即为充值成功，不接受任何理由退款。 "), _c("br"), _vm._v(" 2.获取卡密及兑换方式：购买后,点击充值记录-卡密信息查看。")])])]) : _vm._e()])]), _c("van-dialog", {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      title: "长按扫码联系客服"
    },
    model: {
      value: _vm.showComponyWXImg,
      callback: function callback($$v) {
        _vm.showComponyWXImg = $$v;
      },
      expression: "showComponyWXImg"
    }
  }, [_c("img", {
    attrs: {
      src: require("../../assets/companyWx.png"),
      width: "90%"
    }
  })]), _c("van-dialog", {
    staticClass: "my_dialog",
    attrs: {
      title: (_vm$countryAlertInfo = _vm.countryAlertInfo) === null || _vm$countryAlertInfo === void 0 ? void 0 : _vm$countryAlertInfo.title,
      "confirm-button-text": " 我已知晓并阅读",
      "confirm-button-color": "#000",
      "message-align": "left"
    },
    on: {
      confirm: _vm.countryAlertConfirm
    },
    model: {
      value: _vm.countryAlertVisiable,
      callback: function callback($$v) {
        _vm.countryAlertVisiable = $$v;
      },
      expression: "countryAlertVisiable"
    }
  }, [_c("div", {
    staticStyle: {
      padding: "20px 20px",
      "font-size": "14px",
      color: "#656667"
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "25px"
    },
    domProps: {
      innerHTML: _vm._s(_vm.countryAlertInfo ? _vm.countryAlertInfo.content : "")
    }
  }), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center",
      "margin-top": "10px"
    }
  }, [_c("van-checkbox", {
    attrs: {
      shape: "square"
    },
    on: {
      click: _vm.changeCheckbox
    },
    model: {
      value: _vm.isChecked,
      callback: function callback($$v) {
        _vm.isChecked = $$v;
      },
      expression: "isChecked"
    }
  }, [_vm._v("下次不再提示")])], 1)])]), _c("van-dialog", {
    staticClass: "batch",
    attrs: {
      "confirm-button-text": "进入批量充值页面",
      "confirm-button-color": "#000",
      "message-align": "left",
      "use-title-slot": ""
    },
    on: {
      confirm: _vm.changeCheckboxBatchPopUp,
      cancel: _vm.clickCancelBatchPopUp
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c("span", [_vm._v("温馨提示")]), _c("van-icon", {
          staticStyle: {
            position: "absolute",
            right: "20px",
            top: "20px",
            "font-size": "24px"
          },
          attrs: {
            name: "cross"
          },
          on: {
            click: _vm.clickCancelBatchPopUp
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.btachDialogPopUp,
      callback: function callback($$v) {
        _vm.btachDialogPopUp = $$v;
      },
      expression: "btachDialogPopUp"
    }
  }, [_c("div", {
    staticStyle: {
      padding: "20px 20px",
      "font-size": "14px",
      color: "#656667"
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "25px"
    }
  }, [_vm._v(" 亲爱的用户，系统检测到您可能有批量号码充值的需求"), _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("(多号码一次性提交充值)")]), _vm._v("，可点击蓝色按钮跳转至批量号码充值页面。操作中遇到任何问题，联系游全球人工客服协助。 ")]), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center",
      "margin-top": "10px"
    }
  }, [_c("van-checkbox", {
    attrs: {
      shape: "square"
    },
    model: {
      value: _vm.isCheckedBatch,
      callback: function callback($$v) {
        _vm.isCheckedBatch = $$v;
      },
      expression: "isCheckedBatch"
    }
  }, [_vm._v("下次不再提示")])], 1)])]), _c("van-popup", {
    staticStyle: {
      "text-align": "center",
      "background-color": "transparent"
    },
    model: {
      value: _vm.isSubscribeOfficeVisiable,
      callback: function callback($$v) {
        _vm.isSubscribeOfficeVisiable = $$v;
      },
      expression: "isSubscribeOfficeVisiable"
    }
  }, [_c("img", {
    staticStyle: {
      width: "300px",
      height: "430px",
      "border-radius": "10px"
    },
    attrs: {
      src: "https://oss.globalcharge.cn/prod/wechat/image/officeGuideSubscribe.jpg"
    }
  }), _c("div", {
    staticClass: "couponeUser"
  }, [_c("div", {
    staticClass: "box",
    on: {
      click: function click($event) {
        _vm.isSubscribeOfficeVisiable = false;
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-chahao coupone-chahao"
  })])])]), _c("van-popup", {
    staticStyle: {
      "text-align": "center",
      "background-color": "transparent"
    },
    model: {
      value: _vm.bindresultVisible,
      callback: function callback($$v) {
        _vm.bindresultVisible = $$v;
      },
      expression: "bindresultVisible"
    }
  }, [_vm.bindResult === true ? _c("img", {
    staticStyle: {
      width: "370px",
      height: "430px",
      "border-radius": "10px"
    },
    attrs: {
      src: "https://oss.globalcharge.cn/prod/wechat/src/assets/userCenter/invite1.png"
    },
    on: {
      click: function click($event) {
        _vm.bindresultVisible = false;
      }
    }
  }) : _vm._e(), _vm.bindResult === false ? _c("img", {
    staticStyle: {
      width: "357px",
      height: "352px",
      "border-radius": "10px"
    },
    attrs: {
      src: "https://oss.globalcharge.cn/prod/wechat/src/assets/userCenter/invite2.png"
    },
    on: {
      click: _vm.closeBindResult2
    }
  }) : _vm._e(), _c("div", {
    staticClass: "couponeUser"
  }, [_c("div", {
    staticClass: "box",
    on: {
      click: _vm.closeBindResult
    }
  }, [_c("i", {
    staticClass: "iconfont icon-chahao coupone-chahao"
  })])])]), _c("van-popup", {
    staticClass: "fansGroupPopup",
    model: {
      value: _vm.videoQRCodeVisible,
      callback: function callback($$v) {
        _vm.videoQRCodeVisible = $$v;
      },
      expression: "videoQRCodeVisible"
    }
  }, [_c("img", {
    staticStyle: {
      "border-radius": "10px",
      "max-width": "500px",
      "min-width": "280px"
    },
    attrs: {
      width: "100%",
      src: _vm.wxVideoUrl,
      alt: "扫码看视频"
    }
  })]), _c("van-dialog", {
    staticClass: "my_dialog",
    attrs: {
      title: "注意事项",
      showConfirmButton: false,
      "confirm-button-text": "关闭",
      "confirm-button-color": "#000",
      "message-align": "left"
    },
    on: {
      confirm: _vm.exchangeAlertInfoConfirm
    },
    model: {
      value: _vm.exchangeAlertVisiable,
      callback: function callback($$v) {
        _vm.exchangeAlertVisiable = $$v;
      },
      expression: "exchangeAlertVisiable"
    }
  }, [_c("div", {
    staticStyle: {
      padding: "20px 20px 60px",
      "font-size": "14px",
      color: "#656667",
      position: "relative"
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "25px"
    }
  }, [_c("div", [_vm._v("亲爱的用户:")]), _vm._v(" 此国家暂无流量直充产品， 故本平台为您提供以拨打代码或发短信形式进行流量订购的相关信息(用话费买流量)，订购信息均整理自运营商官网，"), _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("若发生信息变更或因套餐冲突导致订购有误，与本平台无关，以实际到账为准")]), _vm._v("。 ")]), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center",
      "margin-top": "10px"
    }
  }, [_c("van-checkbox", {
    attrs: {
      shape: "square"
    },
    on: {
      click: _vm.changeCheckbox2
    },
    model: {
      value: _vm.exchangeIsChecked,
      callback: function callback($$v) {
        _vm.exchangeIsChecked = $$v;
      },
      expression: "exchangeIsChecked"
    }
  }, [_vm._v("我已同意并知晓")])], 1), _vm.countDown > 0 ? _c("div", {
    staticClass: "closeBtn closeBtn2"
  }, [_vm._v("倒计时" + _vm._s(_vm.countDown) + "秒")]) : _vm._e(), _vm.countDown === 0 ? _c("div", {
    staticClass: "closeBtn",
    staticStyle: {
      width: "50%"
    },
    on: {
      click: _vm.exchangeAlertInfoConfirm
    }
  }, [_vm._v(" 确认 ")]) : _vm._e(), _vm.countDown === 0 ? _c("div", {
    staticClass: "closeBtnCancel",
    on: {
      click: function click($event) {
        _vm.exchangeAlertVisiable = false;
        _vm.exchangeIsChecked = false;
      }
    }
  }, [_vm._v(" 取消 ")]) : _vm._e()])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };